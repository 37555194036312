import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useRef, useState, useEffect } from "react";
import Modal from 'react-modal';
import { isMobile, isTablet, isBrowser, isSafari } from 'react-device-detect';

// import Airplane from "../components/airplane.js"
import ParallaxImage from "../components/parallaxImage.js"
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { gsap } from "../vendor/gsap/src/index.js";
import { CustomEase } from "../vendor/gsap/src/CustomEase.js";
import { ScrollTrigger } from "../vendor/gsap/src/ScrollTrigger.js";

import ReviewCarousel from "../components/review-carousel"

import "./compleetJourney.scss";

gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollTrigger);

const pageQuery = graphql`
    {
        hyco {
            titles {
                indicator
                value
                richValue { html }
            }
            moduleGroups {
                moduleGroupName
                slug
                description {
                    html
                }
                popupInfoButtonText
                popupInfo {
                    html
                }
                containerColor
                parallaxDisplayType
                parallaxAnimationType
                parallaxImage1
                parallaxImage2
                parallaxImage3
            }
        }
    }
`;

let customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: '20px 20px 35px',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        borderRadius: '20px',
        backgroundColor: '#00357d',
        border: 0,
        color: 'white',
        maxHeight: '75vh',
        boxShadow: '0px 0px 10px white'
    }
};

if (isMobile && isTablet === false) {
    customStyles.content.width = '300px';
}

if (isMobile && isTablet) {
    customStyles.content.width = '600px';
}

let mainText = '';
let subText = '';
let subVvkr = '';
let customerJourneyTitle = '';
let finalContainerTitle = '';
let finalContainerText = '';
let finalContainerBottom = '';
let startContainerText = '';
let voorwaardenText = '';
let planButton = '';
let detailsButton = '';

// let mobileImage = '';
// let tabletImage = '';
// let desktopImage = '';

// let leftParallax = null;
// let rightParallax = null;

let previousScroll = 0;

const refsArray = [];

// const cleanup = () => {
//     document.documentElement.style.overflow = 'unset';
// }

const CompleetJourney = () => {
    const {
        hyco: { titles, moduleGroups }
    } = useStaticQuery(pageQuery);

    for (const title of titles) {
        if (title.indicator === 'startpakket-journey-main') {
            mainText = title;
        }
        if (title.indicator === 'startpakket-journey-sub') {
            subText = title;
        }
        if (title.indicator === 'start-vvkr') {
            subVvkr = title;
        }
        if (title.indicator === 'startpakket-journey-title') {
            customerJourneyTitle = title.value;
        }
        if (title.indicator === 'startpakket-final-container-title') {
            finalContainerTitle = title.value;
        }
        if (title.indicator === 'startpakket-final-container-text') {
            finalContainerText = title.richValue;
        }
        if (title.indicator === 'startpakket-final-container-bottom') {
            finalContainerBottom = title.richValue;
        }
        if (title.indicator === 'startpakket-container-text') {
            startContainerText = title;
        }
        if (title.indicator === 'voorwaarden-text') {
            voorwaardenText = title.richValue;
        }
        if (title.indicator === 'button-gesprek') {
            planButton = title;
        }
        if (title.indicator === 'button-details') {
            detailsButton = title;
        }
    }

    const [modalIsOpen, setIsOpen] = useState(false);
    const [mobile, setMobile] = useState(null);
    const [moduleData, setModuleData] = useState(null);
    const [safari, setSafari] = useState(null);
    const [airplaneRefs, setAirplaneRefs] = useState([]);
    const [urlParam, setUrlParam] = useState("");
    const groupsRef = useRef(null);

    const handleOpenModal = (moduleGroup = null) => {
        setModuleData(moduleGroup)
        setIsOpen(true);
        previousScroll = window.scrollY;
    }

    const handleCloseModal = () => {
        setModuleData(null)
        setIsOpen(false);
    }

    const scrollToRef = (ref) => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({ block: "center" });
        } else {
            if (ref && ref.offsetTop && ref.offsetHeight) {
                window.scrollTo(0, ref.offsetTop + document.querySelector('.header-container').offsetHeight);
            }
        }
    }

    const onScroll = () => {
        if (modalIsOpen) {
            if (previousScroll !== window.scrollY) {
                handleCloseModal();
            }
            previousScroll = window.scrollY;
        }
    }

    if (typeof window !== "undefined") {
        window.addEventListener('scroll', onScroll, true);
    }

    useEffect(() => {
        setUrlParam(window.location.pathname.replace(/\//g, ''));
        Modal.setAppElement('body');
        if (isBrowser) {
            setMobile(true);
        } else {
            setMobile(false);
        }

        if (isSafari === true) {
            setSafari(true);
        } else {
            setSafari(false);
        }
    }, [urlParam, mobile]);

    return (
        <div>
            <div className="compleet-title-container">
                <button className="text-button" onClick={() => scrollToRef(groupsRef)}>
                    <h1>
                        <strong>
                            {mainText.value}
                        </strong>
                    </h1>
                    {urlParam === "start-pakket" && (
                        <h1 className="subtitle">
                            {subText.value}
                        </h1>
                    )}
                    {urlParam === "vvkr" && (
                        <h1 className="subtitle">
                            {subVvkr.value}
                        </h1>
                    )}
                </button>
                <button className="arrow bounce" onClick={() => scrollToRef(groupsRef)}>
                    <div className="mobile-arrow"></div>
                </button>
            </div>
            <div className="compleet-journey-container">
                <div className="start-container container">
                    <div className="row">
                        <div className="col-md">
                            <div className="start-text-container">
                                <div className="html-text inline" dangerouslySetInnerHTML={{ __html: startContainerText.richValue.html }} />
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="initial">
                                <p className="text">Maandelijks</p>
                                <p className="price">€199,-</p>
                            </div>
                            <div className="monthly">
                                <p className="text">Eenmalig</p>
                                <p><span className="price">€2.495,-</span></p>
                                <button className="voorwaarden" onClick={() => handleOpenModal()}>Lees de voorwaarden</button>
                            </div>
                            <img src="https://www.vvkr.nl/assets/img/logo.png" alt="vvkr-logo" />
                        </div>
                    </div>
                </div>
                <div className="review-container">
                    <ReviewCarousel />
                </div>
                <div className="compleet-journey-container airplane-wrapper">
                    <div className="summary-container container" ref={groupsRef}>
                        <h1>{customerJourneyTitle}</h1>
                        <div id="summary-menu-container">
                            <ul className="summary-menu">
                                {moduleGroups.map((moduleGroup, i) => {
                                    return <li key={moduleGroup.slug + 'ref'}>
                                        <button className="orange-button" onClick={() => scrollToRef(refsArray[i])}>{moduleGroup.moduleGroupName}</button>
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>
                    {/* {airplaneRefs.length === modules.length &&
                        <Airplane blocks={airplaneRefs} />
                    } */}

                    {moduleGroups.map((moduleGroup, i) => {
                        return <div id={moduleGroup.slug + "-container"} className={moduleGroup.containerColor + "-container container"} ref={ref => {
                            refsArray[i] = ref;
                            if (airplaneRefs.indexOf(moduleGroup.slug + "-container") < 0) {
                                setAirplaneRefs(airplaneRefs.concat([moduleGroup.slug + "-container"]));
                            }
                        }} key={moduleGroup.slug}>
                            {mobile === true ? (
                                <div className="print-ignore row justify-content-between">
                                    {i % 2 === 1 && (
                                        <div className="col-sm-5 parallax-container">
                                            <ParallaxImage slug={moduleGroup.slug} safariCheck={safari} timeline={moduleGroup.parallaxAnimationType} displayType={moduleGroup.parallaxDisplayType} images={moduleGroup} compleet="true" />
                                        </div>
                                    )}
                                    <div className="col-sm-5">
                                        <div className="row">
                                            <h1>{moduleGroup.moduleGroupName}</h1>
                                            {(moduleGroup.description != null && moduleGroup.description.html != null) &&
                                                <div className="html-text inline" dangerouslySetInnerHTML={{ __html: moduleGroup.description.html }} />
                                            }
                                            {(moduleGroup.popupInfo != null && moduleGroup.popupInfo.html != null) &&
                                                <button className="module-reference" onClick={() => handleOpenModal(moduleGroup)}>
                                                    {moduleGroup.popupInfoButtonText || 'Extra info test'}
                                                    <div className="icon baseline">
                                                        <ArrowRightAltIcon />
                                                    </div>
                                                </button>
                                            }
                                            {/* {moduleGroup.modules && moduleGroup.modules.length > 0 && <span>
                                                <h2>Modules:</h2>
                                                <hr />
                                                <ul className="module-list">
                                                    {moduleGroup.modules.map((module, index) => {
                                                        if (index > 5) return null
                                                        return <li className="module-list-item" key={module.slug}>
                                                            <button onClick={() => handleOpenModal(module)}>
                                                                {module.moduleName}
                                                            </button>
                                                        </li>
                                                    })}
                                                </ul>
                                            </span>} */}
                                        </div>
                                        {/* <div className="row">
                                            <Link className="module-reference" key={moduleGroup.slug} to={`/customer-journey/${moduleGroup.slug}`}>{moduleGroup.moreLinkText}
                                                <div className="icon baseline">
                                                    <ArrowRightAltIcon />
                                                </div>
                                            </Link>
                                        </div> */}
                                    </div>
                                    {i % 2 === 0 && (
                                        <div className="col-sm-5 parallax-container">
                                            <ParallaxImage slug={moduleGroup.slug} safariCheck={safari} timeline={moduleGroup.parallaxAnimationType} displayType={moduleGroup.parallaxDisplayType} images={moduleGroup} compleet="true" />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="print-ignore row justify-content-between">
                                    <div className="col-sm-5">
                                        <div className="row">
                                            <h1>{moduleGroup.moduleGroupName}</h1>
                                            {(moduleGroup.description && moduleGroup.description.html) &&
                                                <div className="html-text inline" dangerouslySetInnerHTML={{ __html: moduleGroup.description.html }} />
                                            }
                                            {(moduleGroup.popupInfo != null && moduleGroup.popupInfo.html != null) &&
                                                <button className="module-reference" onClick={() => handleOpenModal(moduleGroup)}>
                                                    {moduleGroup.popupInfoButtonText || 'Extra info test'}
                                                    <div className="icon baseline">
                                                        <ArrowRightAltIcon />
                                                    </div>
                                                </button>
                                            }
                                            {/* {moduleGroup.modules && moduleGroup.modules.length > 0 && <span>
                                                <h2>Modules:</h2>
                                                <hr />
                                                <ul className="module-list">
                                                    {moduleGroup.modules.map((module, index) => {
                                                        if (index > 5) return null
                                                        return <li className="module-list-item" key={module.slug}>
                                                            <button onClick={() => handleOpenModal(module)}>
                                                                {module.moduleName}
                                                            </button>
                                                        </li>
                                                    })}
                                                </ul>
                                            </span>} */}
                                        </div>
                                        {/* <div className="row">
                                            <Link className="module-reference" key={moduleGroup.slug} to={`/customer-journey/${moduleGroup.slug}`}>{moduleGroup.moreLinkText}
                                                <div className="icon baseline">
                                                    <ArrowRightAltIcon />
                                                </div>
                                            </Link>
                                        </div> */}
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="parallax-container">
                                            <ParallaxImage slug={moduleGroup.slug} safariCheck={safari} timeline={moduleGroup.parallaxAnimationType} displayType={moduleGroup.parallaxDisplayType} images={moduleGroup} compleet="true" />
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="print print-container">
                                <div className="row">
                                    <h1>{moduleGroup.moduleGroupName}</h1>
                                    {(moduleGroup.description && moduleGroup.description.html) &&
                                        <div className="html-text inline" dangerouslySetInnerHTML={{ __html: moduleGroup.description.html }} />
                                    }
                                    {/* {moduleGroup.modules && moduleGroup.modules.length > 0 && <span>
                                        <h2>Modules:</h2>
                                        <hr />
                                        <ul className="module-list">
                                            {moduleGroup.modules.map((module, index) => {
                                                if (index > 5) return null
                                                return <li className="module-list-item" key={module.slug}>
                                                    <button onClick={() => handleOpenModal(module)}>
                                                        {module.moduleName}
                                                    </button>
                                                </li>
                                            })}
                                        </ul>
                                    </span>} */}
                                </div>
                                {/* <div className="row">
                                    <Link className="module-reference" key={moduleGroup.slug} to={`/customer-journey/${moduleGroup.slug}`}>{moduleGroup.moreLinkText}
                                        <div className="icon baseline">
                                            <ArrowRightAltIcon />
                                        </div>
                                    </Link>
                                </div> */}
                            </div>
                            <div className="print print-container">
                                <div className="parallax-container">
                                </div>
                            </div>
                        </div>
                    })}

                    <div id="bottom-container" className="white-container final-container container">
                        <h2>{finalContainerTitle}</h2>
                        {(finalContainerText && finalContainerText.html) &&
                            <div className="html-text inline" dangerouslySetInnerHTML={{ __html: finalContainerText.html }} />
                        }
                        <div className="row">
                            <div className="plan-container">
                                <Link className="plan-button" key="plan-button" to={`/start-demo`}>{planButton.value}</Link>
                                <p className="small-text">Stel al je vragen, online</p>
                            </div>
                            <div className="details-container">
                                {urlParam === "start-pakket" && (
                                    <Link className="details-button" key="details-button" to={`/start-offerte`}>{detailsButton.value}</Link>
                                )}
                                {urlParam === "vvkr" && (
                                    <Link className="details-button" key="details-button" to={`/vvkr-offerte`}>{detailsButton.value}</Link>
                                )}
                                <p className="small-text">Geheel vrijblijvend</p>
                            </div>
                        </div>
                        {(finalContainerBottom && finalContainerBottom.html) &&
                            <div className="html-text inline" dangerouslySetInnerHTML={{ __html: finalContainerBottom.html }} />
                        }
                        {/* <div className="extra-info-form">
                            <form id="form1202" encType="application/x-www-form-urlencoded" className="mpForm" method="post" action="https://m13.mailplus.nl/genericservice/code/servlet/Redirect">
                                <ul className="mpFormTable mpTwoColumnLayout">
                                    <li id="CNT1257" className="mpQuestionTable  ">
                                        <div className="mpFormLabel"><label className="descriptionLabel" htmlFor="field1257">Bedrijfsnaam</label><span className="mandatorySign">&nbsp;*</span></div>
                                        <div className="mpFormField"><input type="text" id="field1257" name="field1257" /></div>
                                    </li>
                                    <li id="CNT1258" className="mpQuestionTable  ">
                                        <div className="mpFormLabel"><label className="descriptionLabel" htmlFor="field1258">VvKR lidmaatschapsnummer</label></div>
                                        <div className="mpFormField"><input type="text" id="field1258" name="field1258" /></div>
                                    </li>
                                    <li id="CNT1259" className="mpQuestionTable  ">
                                        <div className="mpFormLabel"><label className="descriptionLabel" htmlFor="field1259">Voornaam</label><span className="mandatorySign">&nbsp;*</span></div>
                                        <div className="mpFormField"><input type="text" id="field1259" name="field1259" /></div>
                                    </li>
                                    <li id="CNT1260" className="mpQuestionTable  ">
                                        <div className="mpFormLabel"><label className="descriptionLabel" htmlFor="field1260">Achternaam</label><span className="mandatorySign">&nbsp;*</span></div>
                                        <div className="mpFormField"><input type="text" id="field1260" name="field1260" /></div>
                                    </li>
                                    <li id="CNT1261" className="mpQuestionTable  ">
                                        <div className="mpFormLabel"><label className="descriptionLabel" htmlFor="field1261">Telefoonnummer</label><span className="mandatorySign">&nbsp;*</span></div>
                                        <div className="mpFormField"><input type="text" maxLength="12" size="12" id="field1261" name="field1261" /></div>
                                    </li>
                                    <li id="CNT1262" className="mpQuestionTable  ">
                                        <div className="mpFormLabel"><label className="descriptionLabel" htmlFor="field1262">E-mailadres</label><span className="mandatorySign">&nbsp;*</span></div>
                                        <div className="mpFormField"><input type="text" id="field1262" name="field1262" placeholder="naam@bedrijf.nl" /></div>
                                    </li>
                                    <li id="CNT1256" className="mpQuestionTable  ">
                                        <div className="mpLabelRow">
                                            <p className="explanation">* = Dit is een verplicht veld</p>
                                            <div className="submitCellSpacer"><span></span></div>
                                            <div className="submitCell"><input value="Verzenden" className="submitButton" name="next" type="submit" id="field1256" /></div>
                                        </div>
                                    </li>
                                </ul>
                                <fieldset style={{ display: 'none' }}>
                                    <input type="hidden" name="userId" value="2110488" />
                                    <input type="hidden" name="formEncId" value="wHWwTrW3ENzmjHW67Lr6" />
                                    <input type="hidden" name="pagePosition" value="1" />
                                    <input type="hidden" name="viewMode" value="STATICINTEGRATION" />
                                    <input type="hidden" name="redir" value="formAdmin2" />
                                    <input type="hidden" name="formLayout" value="N" />
                                    <input type="hidden" name="errorText" value="Dit formulier kon niet verzonden worden om de volgende reden(en):" />
                                </fieldset>
                            </form>
                        </div> */}
                    </div>
                    <Modal isOpen={modalIsOpen}
                        onRequestClose={handleCloseModal}
                        style={customStyles}
                    >
                        {(moduleData && moduleData.popupInfo && moduleData.popupInfo.html) &&
                            <div className="html-text inline" dangerouslySetInnerHTML={{ __html: moduleData.popupInfo.html }} />
                        }
                        {(moduleData == null && voorwaardenText != null) &&
                            <div className="html-text inline" dangerouslySetInnerHTML={{ __html: voorwaardenText.html }} />
                        }
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default CompleetJourney
